import {store} from '../Components/App';
import {css,} from 'glamor';

const getState = () => {
    if (process.env.NODE_ENV === 'test' && process.env.TestState !== undefined) {
        return JSON.parse(process.env.TestState);
    }
    return store.getState();
}

export default class styles {
    static getHeader = () => {
        const state = getState();
        const config = state.Config.Themes.Header;
        return css({
            background: config.Background + ' !important',
            color: config.Text + ' !important',
        });
    }
    
    static getSection = () => {
        const state = getState();
        const config = state.Config.Themes.Sections;
        return css({
            background: config.Background,
            color: config.Text,
        })
    }
    
    static getTile = () => {
        const state = getState();
        const config = state.Config.Themes.Tiles;
        return css({
            background: config.Background,
            color: config.Text,
        })
    }
    
    static getButton = () => {
        const state = getState();
        const config = state.Config.Themes.Buttons;
        return css({
            background: config.Background + " !important",
            color: config.Text + ' !important',
            ':active': {
                backgroundColor: config.Background + " !important",
                color: config.Text + ' !important',
            }
        })
    }
    
    static getBackground1 = () => {
        const state = getState();
        const config = state.Config.General;
        let background;
        background = css({
            backgroundImage: config.BackgroundUrl1,
            background: config.BackgroundColor1
        })
        return background;
    }

    static getBackground2 = () => {
        const state = getState();
        const config = state.Config.General;
        let background;
        background = css({
            backgroundImage: config.BackgroundUrl2,
            background: config.BackgroundColor2
        })
        return background;
    }

    static getBackground3 = () => {
        const state = getState();
        const config = state.Config.General;
        let background;
        background = css({
            backgroundImage: config.BackgroundUrl3,
            background: config.BackgroundColor3
        })
        return background;
    }

    static getText1 = () => {
        const state = getState();
        const config = state.Config.Themes.Header;
        let text;
        text = css({
            color: config.Text,
        })
        return text;
    }

    static getText2 = () => {
        const state = getState();
        const config = state.Config.General;
        let text;
        text = css({
            color: config.TextColor2,
        })
        return text;
    }

    static getButton1 = () => {
        const state = getState();
        const config = state.Config.Themes.Header;
        let text;
        text = css({
            backgroundImage: config.BackgroundUrl2 + " !important",
            background: config.BackgroundColor2 + " !important",
            ':active': {
                backgroundImage: config.BackgroundUrl1 + " !important",
                background: config.BackgroundColor1 + " !important",
                color: config.TextColor1,
            }
        })
        return text;
    }

    static getButton2 = () => {
        const state = getState();
        const config = state.Config.Themes.Header;
        return css({
            background: config.Background + " !important",
            color: config.Text + ' !important',
            ':active': {
                backgroundColor: config.Background + " !important",
                color: config.Text + ' !important',
            }
        })
    }

    static getInput = () => {
        const state = getState();
        const config = state.Config.Themes.Header;
        let input;
        input = css({
            ':focus': {
                boxShadow: "0 1px 0 0 " + config.Text + " !important",
                borderBottom: "1px solid " + config.Text + " !important",
            },
            ':focus + label': {
                // boxShadow: "0 1px 0 0 " + config.TextColor1 + " !important",
                // borderBottom: "1px solid " + config.TextColor1 + " !important",
                color: config.Text + " !important",
            },
            '+ label:after': {
                color: config.Text + " !important"
            }
        })

        return input;
    }

    static getDatePicker = () => {
        let picker;
        picker = {
            overrides: {
                MuiPickersToolbar: {
                    toolbar: {
                        backgroundColor: '#535353',
                    },
                },
                MuiPickersDay: {
                    day: {
                        color: '#535353'
                    },
                    selected: {
                        color: '#535353'
                    },
                },
                MuiPickersYear: {
                    year: {
                        color: '#535353',
                    },
                },
                MuiPickersModal: {
                    dialogAction: {
                        color: '#535353'
                    },
                },
            }
        }
        return picker;
    }
}
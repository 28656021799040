import actionTypes from '../Redux/ActionTypes';
import {ofType} from 'redux-observable';
import {catchError, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {updateCoupons, updateCustomer} from '../Redux/Actions/Customer';
import {getServiceUrls} from './ServiceUrls';
import {ajax} from 'rxjs/ajax'
import {of} from "rxjs";
import {signupComplete, signupCustomerError} from '../Redux/Actions/Signup';
import {setSessionKey} from "../Redux/Actions/AppConfig";

export const signupCustomerEpic = (action$, state$, {postJSON}) =>
    action$.pipe(
        ofType(actionTypes.Signup.signupCustomer),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            signupCustomerCall(action, state, postJSON)
        )
    )

const signupCustomerCall = (action, state, postJSON) => {
    const uuidv4 = require('uuid/v4');
    const uuid = uuidv4();

    const birthDate = new Date(state.Signup.BirthDate)
    let utcBirthDate = null;
    if (birthDate)
        utcBirthDate = new Date(Date.UTC(birthDate.getFullYear(), birthDate.getMonth(), birthDate.getDate()));

    const customer = {
        FirstName: state.Signup.FirstName,
        LastName: state.Signup.LastName,
        EmailAddress: state.Signup.EmailAddress,
        Phone1: state.Signup.Phone,
        Address1: state.Signup.Address1,
        Address2: state.Signup.Address2,
        City: state.Signup.City,
        State: state.Signup.State,
        CountryCode: state.Signup.Country,
        PostalCode: state.Signup.Postal,
        MobilePhone: state.Signup.MobilePhone,
        FacebookId: state.Signup.FacebookId,
        TwitterId: state.Signup.TwitterId,
        MobileDeviceId: state.Signup.MobileDeviceId,
        BirthDate: utcBirthDate,
    }
    const body = {
        ...customer,
        CommandUid: uuid,
        Password: state.Signup.Password,
    }
    console.log(body)
    const deviceCode = state.Config.General.DeviceCode;
    const domain = state.Config.BrandCode;
    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajaxPost(x.LoyaltyEngineApiUrl + '/api/v4/' + domain + '/' + deviceCode + '/snippets/customer/commands/signups', JSON.stringify(body))
            .pipe(
                mergeMap(response => processSignupCustomer(response.response)),
                catchError(error => of(signupCustomerError(error)))
            )));
}

const ajaxPost = (url, body) => {
    return ajax.post(url, body, {'Content-Type': 'application/json'})
}

const processSignupCustomer = (response) => {
    console.log(response)
    if (response.status === 'error') {
        return of(signupCustomerError(response.message))
    } else {
        return of(updateCustomer(response.data), setSessionKey(response.data.SessionKey), signupComplete());
    }
}
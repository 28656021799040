import actionTypes from '../ActionTypes';

export const updateSignupValue = (fieldName, value) => {
    return {
        type: actionTypes.Signup.updateSignupValue,
        fieldName,
        value,
    }
}

export const signupValidationError = (issues) => {
    return {
        type: actionTypes.Signup.signupValidationError,
        issues,
    }
}

export const signupConfirmation = () => {
    return {
        type: actionTypes.Signup.signupConfirmation,
    }
}

export const backToSignup = () => {
    return {
        type: actionTypes.Signup.backToSignup,
    }
}

export const signupCustomer = () => {
    return { type: actionTypes.Signup.signupCustomer }
}

export const signupComplete = () => {
    return { type: actionTypes.Signup.signupComplete }
}

export const signupCustomerError = (error) => {
    return {
        type: actionTypes.Signup.signupCustomerError,
        error,
    }
}

export const resetSignupError = () => {
    return {
        type: actionTypes.Signup.resetSignupError,
    }
}
import actionTypes from '../ActionTypes';

export const updateCustomer = (customer) => {
    return {
        type: actionTypes.Customer.updateCustomer,
        customer,
    }
}

export const restoreCustomerSession = (sessionKey) => {
    return {
        type: actionTypes.Customer.restoreCustomerSession,
        sessionKey,
    }
}

export const updateCustomerField = (field, value) => {
    return {
        type: actionTypes.Customer.updateCustomerField,
        field,
        value,
    }
}

export const updateCoupons = (awarded, standard) => {
    return {
        type: actionTypes.Customer.updateCoupons,
        awarded,
        standard
    }
}

export const postCustomer = (customer) => {
    return {
        type: actionTypes.Customer.postCustomer,
        customer
    }
}

export const exitEditMode = () => {
    return {
        type: actionTypes.Customer.exitEditMode,
    }
}

export const editCustomer = () => {
    return {
        type: actionTypes.Customer.editCustomer,
    }
}

export const clearStatus = () => {
    return {
        type: actionTypes.Customer.clearStatus,
    }
}

export const signoutCustomer = () => {
    return { type: actionTypes.Customer.signout }
}

export const postCustomerError = (error) => {
    return { type: actionTypes.Customer.postCustomerError, error,}
}

export const postCustomerSuccess = (response) => {
    return { type: actionTypes.Customer.postCustomerSuccess, response}
}

export const redeemCoupon = (code) => {
    return {type: actionTypes.Customer.redeemCoupon, code}
}

export const redeemCouponSuccess = (code) => {
    return {type: actionTypes.Customer.redeemCouponSuccess, code}
}

export const redeemCouponError = (error) => {
    return {type: actionTypes.Customer.redeemCouponError, error}
}

export const getCoupons = () => {
    return {type: actionTypes.Customer.getCoupons}
}

export const getCouponsSuccess = () => {
    return {type: actionTypes.Customer.getCouponsSuccess}
}

export const getCouponsError = () => {
    return {type: actionTypes.Customer.getCouponsError}
}

export const timeoutCoupon = (code) => {
    return { type: actionTypes.Customer.timeoutCoupon, code}
}
import React, { Suspense, lazy } from 'react';
import {Provider} from 'react-redux';

import '../App.css';
import configureStore from '../Redux/Store';
import Layout from './Layout';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import LoadingSpinner from "./LoadingSpinner";
import {QueryClient, QueryClientProvider} from "react-query";

export const store = configureStore();
const queryClient = new QueryClient()

const Landing = lazy(() => import(/* webpackChunkName: 'landingpage' */ './LandingPage'))
const Login = lazy(() => import(/* webpackChunkName: 'loginpage' */ './Login'))
const HomePage = lazy(() => import(/* webpackChunkName: 'homepage' */ './Home'))
const AccountPage = lazy(() => import(/* webpackChunkName: 'accountpage' */'./Account'))
const Rewards = lazy(() => import(/* webpackChunkName: 'rewardspage' */ './Rewards'))
const EditCustomerForm = lazy(() => import(/* webpackChunkName: 'editcustomerpage' */ './EditCustomerForm'))
const SignupForm = lazy(() => import(/* webpackChunkName: 'signuppage' */ './SignupForm'))
const SignupComplete = lazy(() => import(/* webpackChunkName: 'signupcompletepage' */ './SignupComplete'))
const HomePagePreview = lazy(() => import(/* webpackChunkName: 'homepagepreview' */ './Previews/Home/HomePagePreview'))
const HomePageMenuPreview = lazy(() => import(/* webpackChunkName: 'homepagemenupreview' */ './Previews/Home/HomePageMenuPreview'))
const SignupPagePreview = lazy(() => import(/* webpackChunkName: 'signuppagepreview' */ './Previews/Signup/SignupPagePreview'))
const DateInput = lazy(() => import(/* webpackChunkName: 'dateinput' */ './DateInput'))
const SnippetContainer = lazy(() => import(/* webpackChunkName: 'snippetcontainer' */ './SnippetContainer'))

function App() {
    return (
        <Provider store={store}>
            <Layout>
                <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Suspense fallback={<LoadingSpinner/>}>
                        <div className="App">
                            <Switch>
                                <Route exact path='/:domain/' component={Landing}/>
                                <Route path='/:domain/login' component={Login}/>
                                <Route path='/:domain/home' component={HomePage}/>
                                <Route path='/:domain/account' component={AccountPage}/>
                                <Route path='/:domain/rewards' component={Rewards}/>
                                <Route path='/:domain/editprofile' component={EditCustomerForm} />
                                <Route path='/:domain/signup' component={SignupForm}/>
                                <Route path='/:domain/signupcomplete' component={SignupComplete}/>
                                <Route path='/:domain/preview/home' component={HomePagePreview}/>
                                <Route path='/:domain/preview/menu' component={HomePageMenuPreview}/>
                                <Route path='/:domain/preview/signup' component={SignupPagePreview}/>
                                <Route path='/:domain/date' component={DateInput}/>
                                <Route path='/:domain/engagements/:code' component={SnippetContainer} />
                                <Route path='/:domain/snippet/:code' component={SnippetContainer} />
                            </Switch>
                        </div>
                    </Suspense>
                </BrowserRouter>
                </QueryClientProvider>
            </Layout>
        </Provider>
    );
}

export default App;

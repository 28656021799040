import actionTypes from '../ActionTypes';

export const loginCustomer = (username, password) => {
    return {
        type: actionTypes.Login.loginCustomer,
        username,
        password,
    }
}

export const loginCustomerSuccess = (data) => {
    return {
        type: actionTypes.Login.loginCustomerSuccess,
        data,
    }
}

export const loginCustomerFailure = (error) => {
    return {
        type: actionTypes.Login.loginCustomerFailure,
        error,
    }
}

export const tryLocalLogin = () => {
    return {
        type: actionTypes.Login.tryLocalLogin
    }
}

export const tryLocalLoginSuccess = (customer) => {
    return {
        type: actionTypes.Login.tryLocalLoginSuccess,
        customer
    }
}

export const tryLocalLoginFailure = () => {
    return {
        type: actionTypes.Login.tryLocalLoginFailure,
    }
}

export const resetLoginError = () => {
    return {
        type: actionTypes.Login.resetLoginError,
    }
}

export const signoutLogin = () => {
    return { type: actionTypes.Login.signout }
}
import actionTypes from '../Redux/ActionTypes';
import {ofType} from 'redux-observable';
import {catchError, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
    loginCustomerFailure,
    loginCustomerSuccess,
    tryLocalLoginFailure,
    tryLocalLoginSuccess
} from '../Redux/Actions/Login';
import {
    getCouponsError,
    postCustomerError,
    postCustomerSuccess, redeemCouponError, redeemCouponSuccess,
    restoreCustomerSession,
    updateCoupons,
    updateCustomer
} from '../Redux/Actions/Customer';
import {of} from 'rxjs';
import {ajax} from 'rxjs/ajax'
import {getServiceUrls} from './ServiceUrls';
import Cookies from 'js-cookie';
import {setSessionKey} from '../Redux/Actions/AppConfig';
import {getWithServiceUrls} from "../Helpers/HttpFunctions";

export const loginCustomerEpic = (action$, state$, { getJSON, postJSON }) =>
    action$.pipe(
        ofType(actionTypes.Login.loginCustomer),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            loginCustomerCall(action, state, postJSON)
        )
    )

const loginCustomerCall = (action, state, postJSON) => {
    const domain = state.Config.BrandCode;
    const deviceCode = state.Config.General.DeviceCode;
    const body = {
        EmailAddress: action.username,
        Password: action.password,
    }

    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajax.post(x.LoyaltyEngineApiUrl + '/api/v4/' + domain + '/' + deviceCode + '/sessions/commands/createsession', JSON.stringify(body), {'Content-Type': 'application/json'})
        .pipe(
            mergeMap(response => processLoginCustomer(response, action)),
            catchError(error => of(loginCustomerFailure(error)))
        )),
        catchError(error => of(loginCustomerFailure(error)))
    );
}

const processLoginCustomer = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return of(action)
            }
            return of(loginCustomerFailure(blResponse.message))
        } else {
            return of(loginCustomerSuccess(blResponse.data))
        }
    } else {
        return of(loginCustomerFailure(response.message))
    }
}

export const loginCustomerSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.Login.loginCustomerSuccess),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            loginCustomerSuccessCall(action, state)
        )
    )

const loginCustomerSuccessCall = (action, state) => {
    return of(updateCustomer(action.data.Customer), updateCoupons(action.data.AwardedCoupons, action.data.StandardCoupons), setSessionKey(action.data.SessionKey));
}

export const tryLocalLoginEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.Login.tryLocalLogin),
        withLatestFrom(state$),
        switchMap(([action, state]) => 
            tryLocalLoginCall(action, state)
        )
    )

const tryLocalLoginCall = (action, state) => {
    var sessionKey = Cookies.getJSON("sessionKey")
    if (sessionKey !== undefined && sessionKey !== "undefined") {
        return of(setSessionKey(sessionKey), restoreCustomerSession(sessionKey));
    } else {
        return of(tryLocalLoginFailure());
    }
}

export const postCustomerEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.Customer.postCustomer),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            postCustomerCall(action, state)
        )
    )

const postCustomerCall = (action, state) => {
    const domain = state.Config.BrandCode;
    const deviceCode = state.Config.General.DeviceCode

    const birthDate = new Date(action.customer.BirthDate)
    let utcBirthDate = new Date(Date.UTC(birthDate.getFullYear(), birthDate.getMonth(), birthDate.getDate()));
    
    const body = {
        ...action.customer,
        BirthDate: utcBirthDate
    }
    
    let jsonBody = JSON.stringify(body);

    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajax.post(x.WebSnippetsApiUrl + '/api/v4/' + domain + '/' + deviceCode + '/customers?sessionKey=' + state.Config.SessionKey, jsonBody, {'Content-Type': 'application/json'})
            .pipe(
                mergeMap(response => processPostCustomer(response, action)),
                catchError(error => of(postCustomerError(error)))
            )));
}

const processPostCustomer = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return of(action)
            }
            return of(postCustomerError(blResponse.message))
        } else {
            return of(postCustomerSuccess(blResponse))
        }
    } else {
        return of(postCustomerError(response.message))
    }
}

export const redeemCouponEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.Customer.redeemCoupon),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            redeemCoupon(action, state)
        )
    )

const redeemCoupon = (action, state) => {
    const domain = state.Config.BrandCode;
    const deviceCode = state.Config.General.DeviceCode;
    let sessionKey = action.sessionKey ? action.sessionKey : state.Config.SessionKey;
    
    const body = {CouponCode: action.code}
    const jsonBody = JSON.stringify(body)

    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajax.post(`${x.LoyaltyEngineApiUrl}/api/v4/${domain}/${deviceCode}/sessions/${sessionKey}/coupons/commands/redeem`, jsonBody, {'Content-Type': 'application/json'})
            .pipe(
                mergeMap(response => processRedeemCoupon(response, action)),
                catchError(error => of(redeemCouponError(error)))
            )));
}

const processRedeemCoupon = (response, action) => {
    if (response.status === 200) {
        const blResponse = response.response;
        if (blResponse.status === 'error') {
            if (blResponse.message.startsWith("Client not assigned to this cluster")) {
                Cookies.remove("serviceUrls");
                return of(action)
            }
            return of(redeemCouponError(blResponse.message))
        } else {
            return of(redeemCouponSuccess(action.code))
        }
    } else {
        return of(redeemCouponError(response.message))
    }
}

export const getCouponsEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.Customer.getCoupons),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            getCouponsCall(action, state)
        ),
    );


const getCouponsCall = (action, state) => {
    const domain = state.Config.BrandCode;
    const deviceCode = state.Config.General.DeviceCode;
    let sessionKey = action.sessionKey ? action.sessionKey : state.Config.SessionKey;


    const urlBuilder = x => x.LoyaltyEngineApiUrl + '/api/v4/' + domain + '/' + deviceCode + '/sessions/' + sessionKey

    return getWithServiceUrls(domain, urlBuilder, processGetCouponsCall, loginCustomerFailure);
}

const processGetCouponsCall = (response) => {
    if (response.status === 'error') {
        return of(getCouponsError(response.message))
    } else {
        return of(updateCoupons(response.data.AwardedCoupons, response.data.StandardCoupons));
    }
}

// export const setLocalCustomerEpic = (action$, state$) =>
//     action$.pipe(
//         ofType(actionTypes.Login.tryLocalLoginSuccess),
//         withLatestFrom(state$),
//         switchMap(([action, ]) =>
//             setLocalCustomerCall(action)
//         )
//     )

// const setLocalCustomerCall = (action) => {
//     // return of(updateCustomer(action.customer));
// }
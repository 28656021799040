import actionTypes from '../Redux/ActionTypes';
import {ofType} from 'redux-observable';
import {delay, mapTo, switchMap, withLatestFrom} from 'rxjs/operators';
import {appConfigError, finishSignout, setAppConfig, setSessionKey} from '../Redux/Actions/AppConfig';
import {of} from 'rxjs';
import {Settings} from '../Settings';
import {loginCustomerFailure, tryLocalLoginSuccess} from '../Redux/Actions/Login';
import {restoreCustomerSession, updateCoupons, updateCustomer} from '../Redux/Actions/Customer';
import {ajaxGet, getWithServiceUrls} from "../Helpers/HttpFunctions";

export const getGeneralAppConfigEpic = (action$, state$, {getJSON}) =>
    action$.pipe(
        ofType(actionTypes.Config.setBrandCode),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            getAppConfigCall(action, state, getJSON)
        )
    )

const getAppConfigCall = (action, state, getJSON) => {
    return ajaxGet("https://" + Settings.ConfigStorageName + ".blob.core.windows.net/config/" + action.brandCode.toLowerCase() + "/settings.json", processGetAppConfig, appConfigError, "No configuration file found. Please double check the url");
}

const processGetAppConfig = (response) => {
    return of(setAppConfig(response));
}


export const launchRestoreSessionEpic = (action$, state$, {getJSON}) =>
    action$.pipe(
        ofType(actionTypes.Config.setGeneralAppConfig),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            restoreSession(action, state)
        )
    )

const restoreSession = (action, state) => {
    if (state.Config.RestoreSession) {
        return of(restoreCustomerSession(state.Config.SessionKey));
    } else {
        return of({type: "empty"})
    }
}


export const signoutEpic = (action$, state$, {getJSON}) =>
    action$.pipe(
        ofType(actionTypes.Config.signout),
        delay(3000),
        mapTo(finishSignout())
    )


export const restoreSessionEpic = (action$, state$) =>
    action$.pipe(
        ofType(actionTypes.Customer.restoreCustomerSession, actionTypes.Customer.postCustomerSuccess),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            restoreSessionCall(action, state)
        ),
    );


const restoreSessionCall = (action, state) => {
    const domain = state.Config.BrandCode;
    const deviceCode = state.Config.General.DeviceCode;
    let sessionKey = action.sessionKey ? action.sessionKey : state.Config.SessionKey;
    
    
    const urlBuilder = x => x.LoyaltyEngineApiUrl + '/api/v4/' + domain + '/' + deviceCode + '/sessions/' + sessionKey

    return getWithServiceUrls(domain, urlBuilder, processRestoreSessionCall, loginCustomerFailure); 
}

const processRestoreSessionCall = (response) => {
    if (response.status === 'error') {
        return of(loginCustomerFailure(response.message), setSessionKey(""))
    } else {
        return of(updateCustomer(response.data.Customer), updateCoupons(response.data.AwardedCoupons, response.data.StandardCoupons), tryLocalLoginSuccess());
    }
}
import {statuses} from "../Helpers/Status";

const initialState = {
    Customer: {
        EditCustomer: false,
        CurrentCustomer: {},
        Status: '',
        FirstName: '',
        LastName: '',
        Id: '',
        LoyaltyPoints: '',
        LoyaltyDollars: '',
        Program: {
            Name: null,
            Tier: 1,
            ImageUrl: null,
            TextColor: null,
        },
        AwardedCoupons: [],
        StandardCoupons: [],
        RedeemedCoupons: [],
        CouponStatus: '',
    },
    Signup: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        Phone: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Country: '',
        Postal: '',
        Status: '',
        Info: '',
        MobilePhone: '',
        FacebookId: '',
        TwitterId: '',
        MobileDeviceId: '',
        BirthDate: '',
    },
    Login: {
        Status: '',
        Error: '',
    },
    Config: {
        Status: statuses.loading,
        BrandCode: '',
        SessionKey: '',
        Message: '',
        Signup: {
            Pages: null
        },
        Landing: {},
        General: {},
        Home: {},
        Rewards: {
            Cards: []
        },
        Themes: {
            Sections: {},
            Header: {}
        },
        Account: {}
    },
}

export default initialState;
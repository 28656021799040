import log from "loglevel";
import {ajax} from "rxjs/ajax";
import {catchError, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {getServiceUrls} from "../Epics/ServiceUrls";
import {appConfigError} from "../Redux/Actions/AppConfig";

export const ajaxGet = (url, onSuccess, onError, errMsg) => {
    // remote.apply(log, {url: url, format: remote.json})
    // log.enableAll();
    // log.trace(url);

    return ajax.getJSON(url)
        .pipe(
            // tap(response => {if (log !== undefined) log(response) }),
            mergeMap(response => onSuccess(response)),
            catchError(error => of(onError(getErrorMessage(error, errMsg))))
        )
}

const getErrorMessage = (error, errMsg) => {
    if (errMsg)
        return errMsg
    
    return error;
}


export const getWithServiceUrls = (domain, urlBuilder, onSuccess, onError, errMsg) => {
    return getServiceUrls(domain).pipe(
        mergeMap((x) => ajaxGet(urlBuilder(x), onSuccess, onError, errMsg)),
        catchError(err => of(appConfigError("Get Service Urls failed")))
    )
}
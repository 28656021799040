import { of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ajax } from 'rxjs/ajax'
import Cookies from 'js-cookie';
import {Settings} from "../Settings";

export const getServiceUrls = (domain) => {
    let serviceUrls = getServiceUrlsLocally();
    if (serviceUrls !== "undefined")
        return of(serviceUrls);

    let domainServer = Settings.DomainServiceUrl || `https://domain.bloyal.io`;
    return ajax.getJSON(domainServer + `/api/v4/serviceurls/` + domain)
        .pipe(
            tap(x => console.log(x)),
            map(x => x.data),
            // map(x => ({...x, LoyaltyEngineApiUrl: "http://localhost:49653"})),
            tap(x => Cookies.set('serviceUrls', x))
        );
}

//const processServiceUrlsCall = (response) => {
//    if (response.status === 'error') {
//        return of((response.message))
//    }
//    else {
//        return of(loginRequestSuccess(response))
//    }
//}

const getServiceUrlsLocally = () => {
    var urls = Cookies.getJSON("serviceUrls");
    if (urls !== undefined && urls !== "undefined") {
        return urls
    } else {
        return "undefined";
    }
}
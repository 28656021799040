import { createStore, applyMiddleware } from 'redux';
import  { rootReducer, rootEpic } from './Root';
import initialState from './InitialState';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getJSONMock, postJSONMock } from '../Helpers/Mocks';
import {createActionLog} from "redux-action-log";




const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  });    
const epicMiddleware = createEpicMiddleware({
  dependencies: { getJSON: url => getJSONMock(url), postJSON: postJSONMock }
});

export const actionLog = createActionLog({limit: 15})

const enhancer = composeEnhancers(
    applyMiddleware(epicMiddleware),
    actionLog.enhancer// other store enhancers if any
  );

export default function configureStore() {
  
  const store = createStore(rootReducer, initialState, enhancer);

    epicMiddleware.run(rootEpic);
    return store;

}
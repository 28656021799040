import initialState from "../InitialState";
import actionTypes from "../ActionTypes";
import { statuses } from "../../Helpers/Status";
import Cookies from 'js-cookie';

export const configReducer = (state = initialState.Config, action) => {
    switch (action.type) {
        case actionTypes.Config.setGeneralAppConfig:
            if (!action.data.General.DeviceCode) {
                return {
                    ...state,
                    ...action.data,
                    Status: statuses.error,
                    Message: "There is no device set for this app, you will not be able to signup or login"
                }
            }
            return {
                ...state,
                ...action.data,
                Status: statuses.finished,
            }
        case actionTypes.Config.setBrandCode:
            return {
                ...state,
                BrandCode: action.brandCode,
                Status: statuses.found,
            }
        case actionTypes.Config.signout:
            Cookies.remove('sessionKey');
            return {
                ...state,
                SignOut: true,
                SessionKey: "",
            }
        case actionTypes.Config.finishSignout:
            return {
                ...state,
                SignOut: false,
            }
        case actionTypes.Config.setSessionKey:
            if (action.key !== "") {
                Cookies.set('sessionKey', action.key);
                return {
                    ...state,
                    SessionKey: action.key,
                    KeyReset: false,
                }
            } else {
                return {
                    ...state,
                    SessionKey: action.key,
                    KeyReset: true,
                }
            }
        case actionTypes.Config.setRestoreSession:
            return {
                ...state,
                RestoreSession: action.value,
            }
        case actionTypes.Config.appConfigError:
            return {
                ...state,
                Status: statuses.error,
                Message: action.error,
            }
        case actionTypes.Config.resetError:
            return {
                ...state,
                Status: statuses.loading,
                Message: ''
            }
        default:
            return state;
    }
}
import initialState from "../InitialState";
import actionTypes from "../ActionTypes";
import { statuses } from "../../Helpers/Status";


export const signupReducer = (state = initialState.Signup, action) => {
    switch (action.type) {
        case actionTypes.Signup.updateSignupValue:
            let place;
            const zippo = require('zippo');
            if (action.fieldName === "Postal" && zippo.validate(action.value)) {
                const zips = require('zipcodes');
                place = zips.lookup(action.value);
                return {
                    ...state,
                    [action.fieldName]: action.value,
                    State: place.state,
                    City: place.city,
                    Country: place.country,
                }
            }
            return {
                ...state,
                [action.fieldName]: action.value,
            }
        case actionTypes.Signup.signupConfirmation:
            return {
                ...state,
                Status: statuses.found,
            }
        case actionTypes.Signup.signupValidationError:
            return {
                ...state,
                Status: statuses.error,
                Error: "The following fields are required and were not provided: " + action.issues.toString(),
            };
        case actionTypes.Signup.backToSignup:
            return {
                ...state,
                Status: '',
                SigningUp: false,
            }
        case actionTypes.Signup.signupComplete:
            return {
                ...state,
                Status: statuses.finished,
                SigningUp: false,
            }
        case actionTypes.Signup.resetSignupError:
            return {
                ...state,
                Status: '',
                Error: '',
            }
        case actionTypes.Signup.signupCustomerError:
            return {
                ...state,
                Status: statuses.error,
                Error: action.error,
                SigningUp: false,
            }
        case actionTypes.Signup.signupCustomer:
            return {
                ...state,
                SigningUp: true,
            }
        default:
            return state;
    }
}
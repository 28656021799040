import initialState from "../InitialState";
import actionTypes from "../ActionTypes";
import { statuses } from "../../Helpers/Status";


export const loginReducer = (state = initialState.Login, action) => {
    switch (action.type) {
        case actionTypes.Login.loginCustomer:
            return {
                ...state,
                Status: statuses.loading,
            }
        case actionTypes.Login.loginCustomerSuccess:
            return {
                ...state,
                Status: statuses.finished,
            }
        case actionTypes.Login.loginCustomerFailure:
            return {
                ...state,
                Status: statuses.error,
                Error: action.error,
            }
        case actionTypes.Login.tryLocalLogin:
            return {
                ...state,
                Status: statuses.loading,
            }
        case actionTypes.Login.tryLocalLoginSuccess:
            return {
                ...state,
                Status: statuses.finished,
            }
        case actionTypes.Login.tryLocalLoginFailure:
            return {
                ...state,
                Status: statuses.notFound,
            }
        case actionTypes.Login.resetLoginError:
            return {
                ...state,
                Status: '',
                Error: '',
            }
        case actionTypes.Login.signout:
            return {
                ...state,
                Status: '',
            }
        default:
            return state;
    }
}
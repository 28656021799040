import actionTypes from '../ActionTypes';

export const getAppConfig = (brandCode) => {
    return {
        type: actionTypes.Config.getGeneralAppConfig,
        brandCode,
    }
}

export const setAppConfig = (data) => {
    return {
        type: actionTypes.Config.setGeneralAppConfig,
        data,
    }
}

export const setBrandCode = (brandCode) => {
    return {
        type: actionTypes.Config.setBrandCode,
        brandCode,
    }
}

export const signoutConfig = () => {
    return {type: actionTypes.Config.signout}
}

export const finishSignout = () => {
    return {type: actionTypes.Config.finishSignout}
}

export const setSessionKey = (key) => {
    return {
        type: actionTypes.Config.setSessionKey,
        key,
    }
}

export const setRestoreSession = (value) => {
    return {
        type: actionTypes.Config.setRestoreSession,
        value,
    }
}

export const appConfigError = (error) => {
    return {
        type: actionTypes.Config.appConfigError,
        error: error,
    }
}

export const resetError = () => {
    return {
        type: actionTypes.Config.resetError,
    }
}
import React, {useEffect} from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import {useDispatch, useSelector} from 'react-redux';
import {MDBContainer} from 'mdbreact';
import styles from '../Helpers/Styles';
import {setRestoreSession, setSessionKey} from '../Redux/Actions/AppConfig';

export default function Layout(props) {
    const dispatch = useDispatch();
    const textColor = styles.getText1();
    const brandCode = useSelector(state => state.Config.BrandCode);

    if (brandCode === '') {
        document.title = 'MyRewards';
    } else {
        document.title = brandCode + ' MyRewards';
        const favicon = document.getElementById('favicon');
        favicon.href = 'https://www.bloyal.com/wp-content/uploads/fbrfg/favicon-32x32.png'
    }

    useEffect(() => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search.toLowerCase());
        if (parsed.sessionkey !== undefined) {
            console.log("restore session")
            dispatch(setRestoreSession(true));
            dispatch(setSessionKey(parsed.sessionkey));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const style = {
        width: '100%',
        maxWidth: 'none',
    }

    return (
        <MDBContainer className={`${textColor} font`} style={style}>
            {props.children}
        </MDBContainer>
    )
}
import React from 'react'
import { useSelector } from 'react-redux';
import { ImpulseSpinner } from 'react-spinners-kit';
import { statuses } from '../Helpers/Status';

export default function LoadingSpinner() {
    const color1 = useSelector(state => state.Config.Themes.Header.Text);
    const color2 = useSelector(state => state.Config.Themes.Header.Background);
    const status = useSelector(state => state.Config.Status);
    let frontColor;
    let backColor;
    if (status !== statuses.finished) {
        frontColor = "#dedede";
        backColor = "#242424"
    } else {
        frontColor = color1;
        backColor = color2;
    }


    return (
        <div className="center-spinner" id="loading">
            <ImpulseSpinner loading={true} size={40} frontColor={frontColor} backColor={backColor} />
        </div>
    )
}
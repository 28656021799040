import { of } from 'rxjs';

export const postJSONMock = (url, body) => {
    switch (url) {
        case "https://domain-azuretest.bloyal.com/api/v4/evttest/ws1/CustomerSessions":
            const username = body.Username;
            switch (username) {
                case "gold@lafamilia.com":
                    const goldMockCustomerResponse = {
                        status: "success",
                        data: loginMockResponseGold
                    }
                    return of(goldMockCustomerResponse);
                case "platinum@lafamilia.com":
                    const platinumMockCustomerResponse = {
                        status: "success",
                        data: loginMockResponsePlatinum
                    }
                    return of(platinumMockCustomerResponse);
                default:
                    const defaultMockCustomerResponse = {
                        status: "success",
                        data: loginMockResponseGold
                    }
                    return of(defaultMockCustomerResponse);
            }
        default:
            const defaultMockPostResponse = {
                status: "success",
                data: loginMockResponseRegular
            }
            return of(defaultMockPostResponse);
    }
}

const loginMockResponseRegular = {
    Customer: {
        FirstName: 'Ryan',
        LastName: 'Christensen',
        Id: '0000174',
        LoyaltyPoints: '174',
        LoyaltyDollars: '25.00',
        Program: {
            Name: '',
            Tier: 1,
            ImageUrl: '',
            TextColor: '',
        }
    },
}


const loginMockResponseGold = {
    Customer: {
        FirstName: 'Ryan',
        LastName: 'Christensen',
        Id: '0000174',
        LoyaltyPoints: '174',
        LoyaltyDollars: '25.00',
        Program: {
            Name: 'Gold Club',
            Tier: 1,
            ImageUrl: 'url(http://getwallpapers.com/wallpaper/full/0/1/2/282924.jpg)',
            TextColor: 'gold',
        }
    },
}

const loginMockResponsePlatinum = {
    Customer: {
        FirstName: 'Ryan',
        LastName: 'Christensen',
        Id: '0000174',
        LoyaltyPoints: '174',
        LoyaltyDollars: '25.00',
        Program: {
            Name: 'Platinum Club',
            Tier: 2,
            ImageUrl: 'url(https://us.123rf.com/450wm/31december/31december1506/31december150602552/41342716-metal-background-or-texture-of-brushed-steel-plate-with-reflections-iron-plate-and-shiny.jpg?ver=6)',
            TextColor: '#b0b0b0',
        }
    },
}

export const getJSONMock = (url) => {
    switch (url) {
        case "https://domain-azuretest.bloyal.com/appconfig/bloyal":
            const bLoyalMock = {
                status: "success",
                data: configMockbLoyal
            }
            return of(bLoyalMock);
        case "https://domain-azuretest.bloyal.com/appconfig/lafamilia":
            const laFamiliaMock = {
                status: "success",
                data: configMockLaFamilia
            }
            return of(laFamiliaMock);
        default:
            const mockDataDefault = {
                status: "success",
                data: signupConfigMockResponse
            }
            return of(mockDataDefault);
    }
}

const configMockLaFamilia = {
    Signup: {
        Validation: [
            "FirstName",
            "LastName",
        ],
        Pages: [
            {
                Title: "Personal Information",
                Groups: [
                    {
                        Group: {
                            Name: "Personal Info",
                            Border: false,
                            Rows: [
                                {
                                    Cols: [
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "FirstName",
                                                Label: "First Name",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "LastName",
                                                Label: "Last Name",
                                                Required: false,
                                            }
                                        }
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "LastName2",
                                                Label: "Last Name 2",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "FirstName2",
                                                Label: "First Name 2",
                                                Required: false,
                                            }
                                        }
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Phone",
                                                Label: "Phone Number",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 25,
                                            Field: {
                                                Type: "email",
                                                FieldName: "Email",
                                                Label: "Email",
                                                Required: true,
                                            }
                                        },
                                    ]
                                },

                            ]
                        }
                    }
                ],
            },
            {
                Title: "Additional Information",
                Groups: [
                    {
                        Group: {
                            Name: "Address",
                            Border: true,
                            Rows: [
                                {
                                    Cols: [
                                        {
                                            Size: 100,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Address1",
                                                Label: "Address",
                                                Required: true,
                                            }
                                        },
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 100,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Address2",
                                                Label: "Address 2",
                                                Required: false,
                                            }
                                        },
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 33,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Postal",
                                                Label: "Postal Code",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 37,
                                            Field: {
                                                Type: "text",
                                                FieldName: "City",
                                                Label: "City",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 15,
                                            Field: {
                                                Type: "text",
                                                FieldName: "State",
                                                Label: "State",
                                                Required: true,
                                            }
                                        },
                                        {
                                            Size: 15,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Country",
                                                Label: "Country",
                                                Required: true,
                                            }
                                        },
                                    ]
                                },
                            ]
                        },

                    },
                    {
                        Group: {
                            Name: "Additional Information",
                            Border: false,
                            Rows: [
                                {
                                    Cols: [
                                        {
                                            Size: 100,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Info",
                                                Label: "How did you hear about us?",
                                                Required: false,
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            },
        ]
    },
    Landing: {
        Tagline: 'Buen Provecho!',
    },
    General: {
        CompanyName: 'La Familia',
        LogoUrl: 'https://bltestmml.blob.core.windows.net/images/lafamilia%2Fcropped_lafamilia_logo.png',

        // Options for background image #1
        // BackgroundUrl1: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_large.jpg)',
        BackgroundUrl1: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_cream_large.jpg)',
        // BackgroundUrl1: null,


        // Options for background image #2
        // BackgroundUrl2: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_cream_large.jpg)',
        BackgroundUrl2: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_large.jpg)',
        // BackgroundUrl2: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_blue_sm.jpg)',
        // BackgroundUrl2: null,


        // Options for background image #3
        // BackgroundUrl3: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_blue_sm.jpg)',
        BackgroundUrl3: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_large.jpg)',
        // BackgroundUrl3: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_cream_large.jpg)',
        // BackgroundUrl3: null,

        //Options for background color #1
        // BackgroundColor1: '#dfdfdf',
        // BackgroundColor1: '#0D3B4E',
        BackgroundColor1: null,

        //Options for background color #2
        // BackgroundColor2: '#393939',
        BackgroundColor2: '#0D3B4E',

        //Options for background color #3
        // BackgroundColor3: '#619700',
        BackgroundColor3: '#0D3B4E',

        // Options for text color #1
        // TextColor1: 'primary',
        TextColor1: '#E03029',

        // Options for text color #2
        // TextColor2: 'primary',
        TextColor2: 'white',

        TextColor3: '#0D3B4E',


        Width: 100,
    },
    Home: {
        Tagline: 'Today at La Familia',
        Cards: [
            {
                Overlay: false,
                Type: 'misc',
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/enchiladas-verdes-thumb.jpg',
                Title: 'Mi Tierra Enchiladas Verdes',
                Body: '',
            },
            {
                Overlay: false,
                Type: 'rewards',
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/chilaquiles-thumb_(1).jpg',
                Title: 'Buy one get one 50% off!',
                Body: 'Mexico City Chilaquiles Verdes are buy one get one 50% off',
            },
            {
                Overlay: true,
                Type: 'news',
                ImageUrl: 'https://www.lafamiliacortez.com/assets/images/site/repeater_blue_sm.jpg',
                Title: 'Conchas for the Cure with Susan G. Koman',
                Body: 'Click here for more info on the event',
            }
        ]
    },
    Rewards: {
        ListTitle: 'Offers',
        Cards: [
            {
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/chilaquiles-thumb_(1).jpg',
                Title: 'Buy one get one 50% off',
                Body: 'Mexico City Chilaquiles Verdes are buy one get one 50% off',
                OfferCode: '00001',
            },
            {
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/enchiladas-verdes-thumb.jpg',
                Title: 'Free Drink',
                Body: 'Receive a free soft drink with the purchase of an entree at all La Familia restaurants',
                OfferCode: '00002',
            },
        ]
    },
    Account: {
    }
}

const configMockbLoyal = {
    Signup: {
        Validation: [
            "FirstName",
            "LastName",
        ],
        Pages: [
            {
                Title: "Personal Information",
                Groups: [
                    {
                        Group: {
                            Name: "Personal Info",
                            Border: false,
                            Rows: [
                                {
                                    Cols: [
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "FirstName",
                                                Label: "First Name",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "LastName",
                                                Label: "Last Name",
                                                Required: false,
                                            }
                                        }
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "LastName2",
                                                Label: "Last Name 2",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "FirstName2",
                                                Label: "First Name 2",
                                                Required: false,
                                            }
                                        }
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 50,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Phone",
                                                Label: "Phone Number",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 25,
                                            Field: {
                                                Type: "email",
                                                FieldName: "Email",
                                                Label: "Email",
                                                Required: true,
                                            }
                                        },
                                    ]
                                },

                            ]
                        }
                    }
                ],
            },
            {
                Title: "Additional Information",
                Groups: [
                    {
                        Group: {
                            Name: "Address",
                            Border: true,
                            Rows: [
                                {
                                    Cols: [
                                        {
                                            Size: 100,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Address1",
                                                Label: "Address",
                                                Required: true,
                                            }
                                        },
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 100,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Address2",
                                                Label: "Address 2",
                                                Required: false,
                                            }
                                        },
                                    ]
                                },
                                {
                                    Cols: [
                                        {
                                            Size: 33,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Postal",
                                                Label: "Postal Code",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 37,
                                            Field: {
                                                Type: "text",
                                                FieldName: "City",
                                                Label: "City",
                                                Required: false,
                                            }
                                        },
                                        {
                                            Size: 15,
                                            Field: {
                                                Type: "text",
                                                FieldName: "State",
                                                Label: "State",
                                                Required: true,
                                            }
                                        },
                                        {
                                            Size: 15,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Country",
                                                Label: "Country",
                                                Required: true,
                                            }
                                        },
                                    ]
                                },
                            ]
                        },

                    },
                    {
                        Group: {
                            Name: "Additional Information",
                            Border: false,
                            Rows: [
                                {
                                    Cols: [
                                        {
                                            Size: 100,
                                            Field: {
                                                Type: "text",
                                                FieldName: "Info",
                                                Label: "How did you hear about us?",
                                                Required: false,
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            },
        ]
    },
    Landing: {
        Tagline: 'Start Earning Loyal Customers Today!',
    },
    General: {
        CompanyName: 'bLoyal',
        LogoUrl: 'https://bltestmml.blob.core.windows.net/images/evttest/transparentbLoyalLogo.png',

        // Options for background image #1
        // BackgroundUrl1: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_large.jpg)',
        // BackgroundUrl1: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_cream_large.jpg)',
        BackgroundUrl1: null,


        // Options for background image #2
        // BackgroundUrl2: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_cream_large.jpg)',
        // BackgroundUrl2: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_large.jpg)',
        // BackgroundUrl2: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_blue_sm.jpg)',
        BackgroundUrl2: null,


        // Options for background image #3
        // BackgroundUrl3: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_blue_sm.jpg)',
        // BackgroundUrl3: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_large.jpg)',
        // BackgroundUrl3: 'url(https://www.lafamiliacortez.com/assets/images/site/repeater_cream_large.jpg)',
        BackgroundUrl3: null,

        //Options for background color #1
        BackgroundColor1: '#dfdfdf',
        // BackgroundColor1: '#0D3B4E',
        // BackgroundColor1: null,

        //Options for background color #2
        BackgroundColor2: '#393939',
        // BackgroundColor2: '#0D3B4E',

        //Options for background color #3
        BackgroundColor3: '#619700',
        // BackgroundColor3: '#0D3B4E',

        // Options for text color #1
        // TextColor1: 'primary',
        TextColor1: '#393939',

        // Options for text color #2
        // TextColor2: 'primary',
        TextColor2: 'white',

        TextColor3: 'primary',


        Width: 100,
    },
    Home: {
        Tagline: 'Right now at bLoyal',
        Cards: [
            {
                Overlay: false,
                Type: 'misc',
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/enchiladas-verdes-thumb.jpg',
                Title: 'Mi Tierra Enchiladas Verdes',
                Body: '',
            },
            {
                Overlay: false,
                Type: 'rewards',
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/chilaquiles-thumb_(1).jpg',
                Title: 'Buy one get one 50% off!',
                Body: 'Mexico City Chilaquiles Verdes are buy one get one 50% off',
            },
            {
                Overlay: true,
                Type: 'news',
                ImageUrl: 'https://www.lafamiliacortez.com/assets/images/site/repeater_blue_sm.jpg',
                Title: 'Conchas for the Cure with Susan G. Koman',
                Body: 'Click here for more info on the event',
            }
        ]
    },
    Rewards: {
        ListTitle: 'Offers',
        Cards: [
            {
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/chilaquiles-thumb_(1).jpg',
                Title: 'Buy one get one 50% off',
                Body: 'Mexico City Chilaquiles Verdes are buy one get one 50% off',
                OfferCode: '00001',
            },
            {
                ImageUrl: 'https://www.lafamiliacortez.com/assets/uploads/general/enchiladas-verdes-thumb.jpg',
                Title: 'Free Drink',
                Body: 'Receive a free soft drink with the purchase of an entree at all La Familia restaurants',
                OfferCode: '00002',
            },
        ]
    },
    Account: {
    }
}


const signupConfigMockResponse = {
    Validation: [
        "FirstName",
        "LastName",
    ],
    Pages: [
        {
            Title: "Personal Information",
            Groups: [
                {
                    Group: {
                        Name: "Personal Info",
                        Border: false,
                        Rows: [
                            {
                                Cols: [
                                    {
                                        Size: 50,
                                        Field: {
                                            Type: "text",
                                            FieldName: "FirstName",
                                            Label: "First Name",
                                            Required: false,
                                        }
                                    },
                                    {
                                        Size: 50,
                                        Field: {
                                            Type: "text",
                                            FieldName: "LastName",
                                            Label: "Last Name",
                                            Required: false,
                                        }
                                    }
                                ]
                            },
                            {
                                Cols: [
                                    {
                                        Size: 50,
                                        Field: {
                                            Type: "text",
                                            FieldName: "LastName2",
                                            Label: "Last Name 2",
                                            Required: false,
                                        }
                                    },
                                    {
                                        Size: 50,
                                        Field: {
                                            Type: "text",
                                            FieldName: "FirstName2",
                                            Label: "First Name 2",
                                            Required: false,
                                        }
                                    }
                                ]
                            },
                            {
                                Cols: [
                                    {
                                        Size: 50,
                                        Field: {
                                            Type: "text",
                                            FieldName: "Phone",
                                            Label: "Phone Number",
                                            Required: false,
                                        }
                                    },
                                    {
                                        Size: 25,
                                        Field: {
                                            Type: "email",
                                            FieldName: "Email",
                                            Label: "Email",
                                            Required: true,
                                        }
                                    },
                                ]
                            },

                        ]
                    }
                }
            ],
        },
        {
            Title: "Additional Information",
            Groups: [
                {
                    Group: {
                        Name: "Address",
                        Border: true,
                        Rows: [
                            {
                                Cols: [
                                    {
                                        Size: 100,
                                        Field: {
                                            Type: "text",
                                            FieldName: "Address1",
                                            Label: "Address",
                                            Required: true,
                                        }
                                    },
                                ]
                            },
                            {
                                Cols: [
                                    {
                                        Size: 100,
                                        Field: {
                                            Type: "text",
                                            FieldName: "Address2",
                                            Label: "Address 2",
                                            Required: false,
                                        }
                                    },
                                ]
                            },
                            {
                                Cols: [
                                    {
                                        Size: 33,
                                        Field: {
                                            Type: "text",
                                            FieldName: "Postal",
                                            Label: "Postal Code",
                                            Required: false,
                                        }
                                    },
                                    {
                                        Size: 37,
                                        Field: {
                                            Type: "text",
                                            FieldName: "City",
                                            Label: "City",
                                            Required: false,
                                        }
                                    },
                                    {
                                        Size: 15,
                                        Field: {
                                            Type: "text",
                                            FieldName: "State",
                                            Label: "State",
                                            Required: true,
                                        }
                                    },
                                    {
                                        Size: 15,
                                        Field: {
                                            Type: "text",
                                            FieldName: "Country",
                                            Label: "Country",
                                            Required: true,
                                        }
                                    },
                                ]
                            },
                        ]
                    },

                },
                {
                    Group: {
                        Name: "Additional Information",
                        Border: false,
                        Rows: [
                            {
                                Cols: [
                                    {
                                        Size: 100,
                                        Field: {
                                            Type: "text",
                                            FieldName: "Info",
                                            Label: "How did you hear about us?",
                                            Required: false,
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                }
            ]
        },
    ]
};
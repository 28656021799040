import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import { customerReducer } from './Reducers/Customer';
import { configReducer } from './Reducers/Config';
import { getGeneralAppConfigEpic, signoutEpic, restoreSessionEpic, launchRestoreSessionEpic, } from '../Epics/ConfigEpics';
import { signupReducer } from './Reducers/Signup';
import {
    getCouponsEpic,
    loginCustomerEpic,
    loginCustomerSuccessEpic,
    postCustomerEpic,
    redeemCouponEpic,
    tryLocalLoginEpic,
} from '../Epics/LoginEpics';
import { loginReducer } from './Reducers/Login';
import { signupCustomerEpic } from '../Epics/SignupEpics';

export const rootReducer = combineReducers({
    Customer: customerReducer,
    Config: configReducer,
    Signup: signupReducer,
    Login: loginReducer,
    routing: routerReducer
}) 

export const rootEpic = combineEpics(
    getGeneralAppConfigEpic,
    loginCustomerEpic,
    loginCustomerSuccessEpic,
    tryLocalLoginEpic,
    // setLocalCustomerEpic,
    postCustomerEpic,
    signupCustomerEpic,
    signoutEpic,
    launchRestoreSessionEpic,
    restoreSessionEpic,
    redeemCouponEpic,
    getCouponsEpic,
);
const actionTypes = {
    Customer: {
        updateCustomer: "customer/updateCustomer",
        restoreCustomerSession: "customer/restoreCustomerSession",
        editCustomer: "customer/editCustomer",
        clearStatus: "customer/clearStatus",
        signout: "customer/signout",
        updateCustomerField: "customer/updateCustomerField",
        exitEditMode: "customer/exitEditMode",
        updateCoupons: "customer/updateCoupons", postCustomer: "customer/postCustomer",
        postCustomerError: "customer/postCustomerError", postCustomerSuccess: "customer/postCustomerSuccess",
        redeemCoupon: "customer/redeemCoupon",
        redeemCouponSuccess: "customer/redeemCouponSuccess",
        redeemCouponError: "customer/redeemCouponError",
        getCoupons: "customer/getCoupons",
        getCouponsSuccess: "customer/getCouponsSuccess",
        getCouponsError: "customer/getCouponsError",
        timeoutCoupon: "customer/timeoutCoupon",
    },
    Signup: {
        updateSignupValue: "signup/updateSignupValue",
        signupValidationError: "signup/signupValidationError",
        signupComplete: "signup/signupComplete",
        backToSignup: "signup/backToSignup",
        signupCustomer: "signup/signupCustomer",
        signupConfirmation: "signup/signupConfirmation",
        signupCustomerError: "signup/signupCustomerError",
        resetSignupError: "signup/resetError",
    },
    Login: {
        loginCustomer: "login/loginCustomer",
        loginCustomerSuccess: "login/loginCustomerSuccess",
        loginCustomerFailure: "login/loginCustomerFailure",
        tryLocalLogin: "login/tryLocalLogin",
        tryLocalLoginSuccess: "login/tryLocalLoginSuccess",
        tryLocalLoginFailure: "login/tryLocalLoginFailure",
        resetLoginError: "login/resetLoginError",
        signout: "login/signout",
    },
    Config: {
        setBrandCode: "config/setBrandCode",
        getCustomerSignupConfig: "config/getCustomerSignupConfig",
        setCustomerSignupConfig: "config/setCustomerSignupConfig",
        getGeneralAppConfig: "config/getGeneralAppConfig",
        setGeneralAppConfig: "config/setGeneralAppConfig",
        getHomePageConfig: "config/getHomePageConfig",
        setHomePageConfig: "config/setHomePageConfig",
        getRewardPageConfig: "config/getRewardPageConfig",
        setRewardPageConfig: "config/setRewardPageConfig",
        getAccountPageConfig: "config/getAccountPageConfig",
        setAccountPageConfig: "config/setAccountPageConfig",
        getLandingPageConfig: "config/getLandingPageConfig",
        setLandingPageConfig: "config/setLandingPageConfig",
        setRestoreSession: "config/setRestoreSession",
        signout: "config/signout",
        finishSignout: "config/finishSignout",
        setSessionKey: "config/setSessionKey",
        appConfigError: "config/appConfigError",
        resetError: "config/resetError",
    }
}

export default actionTypes;
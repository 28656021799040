import initialState from "../InitialState";
import actionTypes from "../ActionTypes";
import Cookies from 'js-cookie';
import {statuses} from "../../Helpers/Status";

export const customerReducer = (state = initialState.Customer, action) => {
    let redeemedCoupons;
    switch (action.type) {
        case actionTypes.Customer.updateCustomer:
            console.log(action.customer)
            Cookies.set('customer', action.customer)
            const value = new Date(action.customer.BirthDate);
            let birthDate = new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate());
            return {...state,
                ...action.customer,
                BirthDate: birthDate,
            };
        case actionTypes.Customer.updateCoupons:
            return {
                ...state,
                AwardedCoupons: action.awarded,
                StandardCoupons: action.standard,
            }
        case actionTypes.Customer.getCouponsError:
            return {
                ...state,
                CouponStatus: statuses.error,
            }
        case actionTypes.Customer.signout:
            Cookies.remove('customer');
            return {
                Status: ''
            }
        case actionTypes.Signup.signupCustomer:
            return {
                ...state,
                Status: statuses.loading,
            }
        case actionTypes.Signup.backToSignup:
            return {
                ...state,
                Status: '',
            }
        case actionTypes.Customer.updateCustomerField:
            return {
                ...state,
                CurrentCustomer: {
                    ...state.CurrentCustomer,
                    [action.field]: action.value,
                }
            }
        case actionTypes.Customer.exitEditMode:
            return {
                ...state,
                EditCustomer: false,
                CurrentCustomer: {}
            }
        case actionTypes.Customer.editCustomer:
            return {
                ...state,
                CurrentCustomer: {...state},
                EditCustomer: true,
            }
        case actionTypes.Customer.postCustomer:
            return {
                ...state,
                SavingCustomer: true,
            }
        case actionTypes.Customer.postCustomerError:
            return {
                ...state,
                Status: statuses.error,
                Error: action.error,
                SavingCustomer: false,
            }
        case actionTypes.Customer.postCustomerSuccess:
            return {
                ...state,
                Status: statuses.found,
                EditCustomer: false,
                SavingCustomer: false
            }
        case actionTypes.Customer.redeemCoupon:
            return {
                ...state,
                CouponStatus: statuses.loading,
            }
        case actionTypes.Customer.redeemCouponSuccess:
            redeemedCoupons = state.RedeemedCoupons;
            redeemedCoupons.push({Code: action.code})
            return {
                ...state,
                CouponStatus: statuses.finished,
                RedeemedCoupons: redeemedCoupons,
            }
        case actionTypes.Customer.redeemCouponError:
            return {
                ...state,
                CouponStatus: statuses.error,
            }
        case actionTypes.Customer.timeoutCoupon:
            redeemedCoupons = state.RedeemedCoupons;
            redeemedCoupons = redeemedCoupons.filter(coupon => coupon.Code !== action.code);
            return {
                ...state,
                RedeemedCoupons: redeemedCoupons 
            }
        case actionTypes.Customer.clearStatus:
            return {
                ...state,
                Status: ''
            }
        default:
            return state;
    }
}